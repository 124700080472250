body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar .nav-item .nav-link {
    color: rgb(11, 30, 241);
    font-size: 1.1rem;
}

.navbar .nav-item:hover .nav-link {
    color: rgb(221, 7, 7);
    text-shadow: rgb(221, 179, 7);
}
#result {
    background-image: url("/public/confetti.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
}
